import 'bootstrap';
import Modal from "bootstrap/js/dist/modal"
import 'jquery-deparam';
import './jquery.nice-select';

import './carousel';
import './navigation';
import './expert-articles';
import './experts-subpage';
import './animations';
import './filters';
import './footer';
import './survey';

$(function () {

	var buttonIcon = function () {
		var comment = document.createComment("Edited in main.js");
		var button = $(".qsm-submit-btn");
		var bt_newsletter = $(".mailpoet_submit");
		var bt_expertsHeader = $(".experts__header");
		var bt_experts = $(".experts__header > .experts__button");

		$(button).wrap("<label class='labelInput your-propositions'></label>");

		$(bt_newsletter).val("wyślij").wrap("<label class='labelInput bt-newsletter'></label>");

		$(".labelInput").prepend(comment);

		// $(bt_expertsHeader).addClass("labelInput");
		$(bt_experts).html("poznaj wszystkich");
	}()

	var selectLook = function () {
		$('select').niceSelect();
	}()


	if (document.getElementById("gs1Modal")) {
    var gs1Modal = new Modal(document.getElementById('gs1Modal'));
    $(setTimeout(function () {
        gs1Modal.show();
    }, 5000))
	}

});