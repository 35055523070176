function positionFooter(footer) {
  let footerHeight = $('footer').outerHeight(true);
  let footerTop = ($(window).height() - footerHeight) + "px";
  
  if (($(document.body).height()) < $(window).height() && $('#CookieDeclaration)').length > 0) {
      footer.css({
          position: "absolute"
      }).animate({
          top: footerTop
      })
  } else {
      footer.css({
          position: "relative"
      })
    }
}

positionFooter($('footer'));
