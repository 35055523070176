import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

// $(document).ready(function(){
//   $('.owl-carousel').owlCarousel();
//   console.log(owlCarousel());
// });

$('.owl-carousel').owlCarousel({
  loop:true,
  margin:30,
  nav: true,
  navText: ['<span class="experts-carousel__nav-item experts-carousel__nav-item--left"><svg><path fill-rule="evenodd"d="M5.161,11.396 L0.583,15.748 C0.193,16.118 -0.002,16.604 -0.002,17.090 C-0.002,17.576 0.193,18.061 0.583,18.432 C1.362,19.173 2.627,19.173 3.406,18.432 L11.393,10.840 C12.174,10.099 12.174,8.897 11.393,8.156 L3.406,0.565 C2.627,-0.177 1.362,-0.177 0.583,0.565 C-0.197,1.306 -0.197,2.507 0.583,3.248 L5.161,7.600 L5.161,11.396 Z"/></svg></span>','<span class="experts-carousel__nav-item experts-carousel__nav-item--right"><svg><path fill-rule="evenodd"d="M5.161,11.396 L0.583,15.748 C0.193,16.118 -0.002,16.604 -0.002,17.090 C-0.002,17.576 0.193,18.061 0.583,18.432 C1.362,19.173 2.627,19.173 3.406,18.432 L11.393,10.840 C12.174,10.099 12.174,8.897 11.393,8.156 L3.406,0.565 C2.627,-0.177 1.362,-0.177 0.583,0.565 C-0.197,1.306 -0.197,2.507 0.583,3.248 L5.161,7.600 L5.161,11.396 Z"/></svg></span>'],
  responsiveClass:true,
  responsive:{
      0:{
          items:1,
      },
      772:{
          items:2,
      },
      1000:{
          items:3,
      }
  }
})