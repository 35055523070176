import moment from 'moment';

function newsItem(newsItem) {
    return `<div data-aos-offset="100" data-aos="fade-up" data-aos-duration="1000" class="col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-4">
  <a href="${newsItem.link}" class="news-item">
      <div class="news-item__background" style="background: url('${newsItem._embedded['wp:featuredmedia'][0].source_url}') center/cover no-repeat;">
      </div>
      <div class="news-item__content">
          <div>
              <p>${(moment(newsItem.date).format('DD.MM.YYYY'))}</p>
              <p>${newsItem._embedded['wp:term'][0][0].name}</p>
          </div>
          <h3>${newsItem.title.rendered}</h3>
          <p>${newsItem.acf.expert.post_title || ""}<br>${(newsItem.acf.expert2) ? newsItem.acf.expert2.post_title || "" : ""}</p>
      </div>
  </a>
</div> `;
}

function pagination(i) {
    return `<li><button class="news-pagination__btn" id="${i}">${i}</button></li>`;
}

function filtersFromUrl(){
    let prefix = {
        '#kategoria-': function(){
            let $target = $('input[type=radio]'+window.location.hash);
            if($target.length > 0){
                $('.category-switcher').each(function(){ this.checked = false; });
                $target[0].checked = true;
            }
        },
        '#katalog-': function(){
            let $target = $('option'+window.location.hash);
            let value = $target.attr('value');
            $('#filter-catalog').val(value);
        },
        '#aktualny-katalog': function(){
            let value = $('#filter-catalog option:nth-child(2)').attr('value');
            $('#filter-catalog').val(value);
        }
    };
    for(let key in prefix){
        
        if(window.location.hash.startsWith(key)){
            prefix[key]();
//            postQueue.add(function(){
//                $('#filter')[0].scrollIntoView(true);
//            });
            return true;
        }
    }
    return false;
}

var xhr = null;

function getPosts(page) {
    let $segmentNews = $('#segmentNews');
    let $itemContainer = $('#newsItemContainer');
    let postsPerPage = 6;
    let $loader = $segmentNews.find('.loader');
    let $filter = $('#filter');
    let filterData = $.deparam($filter.serialize());
    let scroll = function(){};
    if(! page){
        page = 1;
    }else{
        scroll = function(){ $('#filter')[0].scrollIntoView(true); };
    }

    let request = $.extend(filterData, {
        '_embed': true,
        'per_page': postsPerPage,
        'page': page,
    });
    
    $loader.show();
    $itemContainer.html('');

    scroll();
    
    if(xhr){
        xhr.abort();
    }
    
    xhr = $.get('/wp-json/wp/v2/posts', request, function (response, textStatus, jqXHR) {
        xhr = null;
        let totalPages = jqXHR.getResponseHeader('X-WP-TotalPages');
        $loader.hide();
        
        for(let i in response){
            let item = response[i];
            $itemContainer.append(newsItem(item))
        }
        
        generatePagination(page, totalPages);
        
        scroll();
    }, 'json');
}

$('#paginationContainer').on('click', 'button', function(){
    if($(this).hasClass('active')){
        return false;
    }
    let page = $(this).data('pagination');
    
    getPosts(page);
});

function generatePagination(page, totalPage){
    let $paginationSection = $('#paginationSection');
    let $paginationContainer = $('#paginationContainer');
    let $arrLeft = $paginationContainer.find('[data-arr="left"]');
    let $arrRight = $paginationContainer.find('[data-arr="right"]');
    
    if(totalPage > 1){
        $paginationSection.show();
    }else{
        $paginationSection.hide();
    }
    
    $paginationContainer.find('.item').remove();
    
    if(page > 1){
        $arrLeft.show();
        $arrLeft.find('button').data('pagination', page - 1);
    }else{
        $arrLeft.hide();
    }
    
    if(page < totalPage){
        $arrRight.show();
        $arrRight.find('button').data('pagination', page + 1);
    }else{
        $arrRight.hide();
    }
    
    for(let i=1; i<=totalPage; i++){
        let btn = $('<button></button>').attr('type','button').data('pagination', i).html(i);
        let li = $('<li></li>').addClass('item').append(btn);
        
        if(i === page){
            btn.addClass('active');
        }
        
        $arrRight.before(li);
    }
}

if($('#filter').length > 0){
    let hash = filtersFromUrl();
    
    getPosts(hash ? 1 : false);
    
    $(window).bind('hashchange', function() {
        filtersFromUrl();
        getPosts(1);
    });
}

$('[data-filtr]').each(function(){
    $(this).on('change', function(){getPosts()});
})

$('[data-multichecker]').each(function(){
    let multichecker = this;
    let $checkboxes = $($(multichecker).data('multichecker'));
    
    $(multichecker).on('change', function(){
        if(this.checked){
            $('.news-bar__button').addClass('news-bar__button--bold');
        }
        getPosts();
    });
    $checkboxes.on('change', function(){
        $('.news-bar__button').removeClass('news-bar__button--bold');
        getPosts();
    });
    
    if(multichecker.checked){
        $('.news-bar__button').addClass('news-bar__button--bold');
    }
});


