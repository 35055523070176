$(document).ready(function(){

  if(window.location.href.indexOf("ekspert") > -1) {
    let parametrData = $('.expert-articles__ul').data('length');
    $('.expert-articles__ul li:nth-of-type(n+'+(parametrData+1)+')').css('display', 'none');
    
    if(parametrData >= ($('.expert-articles__ul').children().length)) {
      $('.expert-articles__btn').hide();
      $('.expert-articles').css('padding', '3rem 0 5rem');
    }
  
    $('.expert-articles__btn').click(function(){
      if(!$(this).hasClass('active')) {
        $(this).addClass('active');
        $('.expert-articles__ul li:nth-of-type(n+'+(parametrData+1)+')').slideDown();
        $(this).html('Zobacz mniej');
      } else {
        $(this).removeClass('active');
        $('.expert-articles__ul li:nth-of-type(n+'+(parametrData+1)+')').slideUp();
        $(this).html('Zobacz więcej');
      }
    })
  }
})




