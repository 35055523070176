$('.nav__hamburger').click (function() {
  if(!$(this).hasClass('active')) {
    $(this).addClass('active');
    $('.nav__wrapper').slideDown();
  } else {
    $(this).removeClass('active');
    $('.nav__wrapper').slideUp();
  }

  $(this).toggleClass('open');
});

$(window).resize(function(){
  if ($(window).width() > 750) {
    $('.nav__wrapper').css('display', 'flex');
 } else {
    $('.nav__wrapper').css('display', 'none');
 }
});